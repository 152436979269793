<template>
  <div class="Navigation">
    <div class="box">
      <h3>最新上线</h3>
      <p class="info"><span>{{title}}</span><span>【{{schoolName}}】</span></p>
    </div>
    <div style="text-align:center">
      <video
        controls
        :src="newVideo"
        style="width:980px; height:551px;"
      ></video>
    </div>
    <div class="box">
      <h3>已发布学校</h3>
    </div>

    <div class="back_add">
      <div class="threeImg">
        <div class="Containt">
          <div class="iconleft" @click="youhua">
            <i class="el-icon-arrow-left"></i>
          </div>
          <ul
            :style="{
              left: calleft + 'px',
              width: superurl.length * 404 + 'px',
            }"
          >
            <li
              v-for="(item, index) in superurl"
              :key="index"
              @click="yxChange(item,'yfb')"
            >
              <a :href="item.videoPath"><img :src="item.imgUrl"/></a>
            </li>
          </ul>
          <div class="iconright" @click="zuohua">
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <h3>从地图中选择</h3>
      <p class="title">- ​目前仅支持中国 -</p>
      <div
        id="myCharts"
        ref="myCharts"
        style="width:980px;height:980px; margin: auto; margin-top: -100px;"
      ></div>
    </div>
    <div class="box" style="margin-top:-220px; padding-top: 0;">
      <h3>从选择框选择</h3>
      <el-row style="text-align: center; padding-top: 30px;">
        <el-col :span="12">
          <p class="title2">选择省/市/自治区</p>
          <el-select
            v-model="cityValue"
            @change="cityChange"
            style="width: 280px;"
            placeholder="选择省/市/自治区"
          >
            <el-option
              v-for="(item, index) in cityOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <p class="title2" id="mubiao">选择高校</p>
          <el-select
            v-model="yxValue"
            @change="yxChange"
            filterable
            style="width: 280px;"
            placeholder="请选择高校"
          >
            <el-option
              v-for="(item, index) in imgList"
              :key="index"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div class="box">
      <h3>{{ cityValue }}高校</h3>
      <p class="title" id="maodian">按上线时间排列</p>
      <ul class="imgList" v-if="imgList.length">
        <li
          v-for="(item, index) in imgList"
          @click="yxChange(item)"
          :key="index"
        >
          <img :src="item.logo" />
        </li>
        <div style="clear: both;"></div>
      </ul>
      <div
        v-else
        style="text-align: center; background-color: black; line-height: 60px;"
      >
        暂无数据
      </div>
      <br />
      <br />
    </div>

    <div class="footer">
      <div class="bottom">
        <span>{{ systemSettins.copyright }}</span
        ><span>备案号：{{ systemSettins.filings }}</span
        ><span>联系方式：{{ systemSettins.email }}</span>
        <span style="text-decoration: underline; cursor: pointer;" @click="letterFn">致谢与致歉</span>
      </div>
    </div>
    <letter ref="letter"></letter>
  </div>
</template>
<script src="./map/china.json"></script>

<script>
import {
  queryByAreaName,
  videosNewest,
  schoolsHotsapi,
  areas,
  addViews,
  settingsDetail
} from "@/api/Mange/Mange";
import letter from "@/components/letter.vue";

export default {
  components: {
    letter,
  },
  data() {
    return {
      schoolName:'暂无信息',
      title:'暂无信息',
      newVideo: "",
      cityValue: "",
      cityOptions: [],
      yxValue: "",
      imgList: [],
      superurl: [],
      calleft: 0,
      systemSettins: {}
    };
  },
  mounted() {
    this.getnewList();
    this.getMap();
    this.areas();
    settingsDetail().then(res => {
      this.systemSettins = res.data;
    })
  },
  methods: {
    letterFn(){
      this.$refs.letter.showDialog();
    },
    //锚点跳转
    goRule(event) {
      let id = "#" + event;
      document.querySelector(id).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    },
    areas() {
      areas().then((res) => {
        if (res.status) {
          this.cityOptions = res.data;
        } else {
          this.$message("获取省数据失败");
        }
      });
    },
    cityChange(val) {
      queryByAreaName(val).then((reps) => {
        this.imgList = reps.data;
      });
    },
    yxChange(row,type) {
      console.log(row, type)
      if (row.hasVideo) {
        this.$router.push({
          path: "/Campus/" + row.id,
        });
      } else {
        if (type == 'yfb') {
          this.$router.push({
            path: "/Campus/" + row.id,
          });
        } else {
          this.$router.push({path: "/404"});
        }
      }
    },

    // 地图
    getMap() {
      let This = this;
      //自动切换定时器， 自动切换项目
      let timer = null,
        nows = -1;

      //初始Echarts实例对象
      const oMap = this.$echarts.init(document.querySelector("#myCharts"));

      //指定加载省、市、县、区 （注：这里是重点！！！，zunyi是zunyi.js中大数据变量，而用引号括起来的'china'是要在map: 'china',中加载的变量）
      //  this.$echarts.registerMap('china', china);

      //（注：由于这里没有用模块化方式导入，所以把zunyi.json文件改为zunyi.js文件，并在里面用一个zunyi常量来引入的）

      //如果你当前环境支持模块化导入方式的话可以直接导入xxxx.json文件，就不用向上面改成js文件后用常量来引入
      this.$echarts.registerMap("china", require("./js/china.json"));

      //（注：想问zunyi.json文件是从哪里来的（或者 是想要其他省、市、县区等），请再向下看！！）

      //图片相关配置信息
      oMap.setOption({
        //标题文本配置
        //  title: {
        //      text: '从地图中选择',
        //      textStyle: {
        //          color: 'white',
        //          fontStyle: 'normal',
        //          fontWeight: 'bold',
        //          fontSize: 32,
        //          lineHeight: 80,
        //          textBorderColor: 'green',
        //          textBorderWidth: 1,
        //          textShadowColor: 'green',
        //          textShadowBlur: 10,
        //          textShadowOffsetX: 2,
        //          textShadowOffsetY: 2
        //      }
        //  },

        //提示框组件(可以设置在多种地方)
        //  tooltip: {
        //      show: true, //是否显示提示框组件，包括提示框浮层和 axisPointer。
        //      trigger: 'item', //触发类型。item,axis,none
        //      enterable: true,//鼠标是否可进入提示框浮层中，默认为false，
        //      showContent: true,          //是否显示提示框浮层
        //      triggerOn: 'mousemove',//提示框触发的条件(mousemove|click|none)
        //      showDelay: 0,               //浮层显示的延迟，单位为 ms，默认没有延迟，也不建议设置。在 triggerOn 为 'mousemove' 时有效。
        //      textStyle: {
        //          color: 'white',
        //          fontSize: 12
        //      },
        //      padding: [0, 8],
        //      hideDelay: 10,             //浮层隐藏的延迟
        //      formatter: (o) => (o.data) ? `<section class="map-hover-box"><div class="map-hover-mov"><h3>建筑地址：${o.name}</h3></div><i></i></section>` : '',
        //      // backgroundColor: 'green',  //提示框浮层的背景颜色。
        //      // borderColor: "white",  //图形的描边颜色
        //      // borderWidth: 2,
        //      alwaysShowContent: true,
        //      transitionDuration: 1,      //提示框浮层的移动动画过渡时间，单位是 s，设置为 0 的时候会紧跟着鼠标移动。
        //  },

        //地理坐标系组件。地理坐标系组件用于地图的绘制，支持在地理坐标系上绘制散点图，线集。
        //  geo: {
        //      show: true,
        //      map: 'china',
        //      roam: false,
        //      top: 90,
        //      left: 306,
        //      zoom: 1.02,
        //      aspectScale: 0.9,
        //      itemStyle: {
        //          normal: {
        //              opacity: 1,              //图形透明度 0 - 1
        //              borderColor: "yellow",  //图形的描边颜色
        //              borderWidth: 2,          //描边线宽。为 0 时无描边。
        //              borderType: 'solid',     //柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
        //              areaColor: "yellow",   //图形的颜色 #eee
        //          }
        //      }
        //  },

        //系列列表。每个系列通过 type(map, scatter, bar, line, gauge, tree.....) 决定自己的图表类型
        series: [
          {
            name: "中国",
            map: "china",
            type: "scatter",
            coordinateSystem: "geo",
            symbolSize: 0,
            silent: "none",
            // data: mapData, //,
            //  data: zunyiData.map(item => {
            //      return {
            //          name: item.name,
            //          value: [item.lng, item.lat]
            //      };
            //  })
          },
          {
            map: "china",
            type: "map",
            zoom: 1, //当前视角的缩放比例。
            aspectScale: 0.9, //这个参数用于 scale 地图的长宽比。geoBoundingRect.width / geoBoundingRect.height * aspectScale
            roam: false, //是否开启鼠标缩放和平移漫游。默认不开启
            label: {
              show: false,
              textStyle: {
                color: "white",
                fontSize: 12,
                backgroundColor: "", //文字背景色
              },
            },

            itemStyle: {
              normal: {
                borderColor: "#0e092b", //图形的描边颜色
                borderWidth: 2, //描边线宽。为 0 时无描边。
                borderType: "solid", //柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
                areaColor: "#6b020b", //图形的颜色 #eee
                //  shadowBlur: 100,            //图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。
                //  shadowColor: 'yellow',     //阴影色
                //  shadowOffsetX: 20,          //X轴阴影
                //  shadowOffsetY: -20,         //Y轴阴影

                label: {
                  show: false,
                  textStyle: {
                    color: "#fff",
                    fontSize: 14,
                  },
                },
              },
              //鼠标移入时
              emphasis: {
                borderColor: "#005b89",
                borderWidth: "1",
                areaColor: "#fc3845",
                label: {
                  show: true,
                  textStyle: {
                    color: "#fff",
                    fontSize: 14,
                  },
                },
              },

              //  effect: {
              //      show: true,
              //      shadowBlur: 10,
              //      loop: true
              //  },
            },
            select: {
              // 地图选中区域样式
              label: {
                // 选中区域的label(文字)样式
                color: "#fff",
              },
              itemStyle: {
                // 选中区域的默认样式
                areaColor: "#fc3845",
              },
            },

            //自定义图片数组对象[{}, {}...]
          },
          //  , ...zunyiData.map((item, index) => {
          //      return {
          //          type: "scatter",
          //          coordinateSystem: "geo",
          //          //自定义图片的 位置（lng, lat）
          //          data: [{ name: item.name, value: [item.lng, item.lat] }],
          //          //自定义图片的 大小
          //          symbolSize: [80, 60],
          //          //自定义图片的 路径
          //          symbol: `image://img/icon/icon (${index}).png`
          //      }
          //  })
        ],
      });

      //自动切换
      //  const autoShow = (length) => {
      //      nows = (nows + 1) % length;
      //      oMap.dispatchAction({
      //          type: 'showTip',
      //          seriesIndex: 0,
      //          dataIndex: nows
      //      });
      //  };

      //每隔5秒钟自动切换到下一个数据点
      //  autoShow(zunyiData.length);
      //  clearInterval(timer);
      //  timer = window.setInterval(() => {
      //      autoShow(zunyiData.length);
      //  }, 5000);

      // 点击事件
      oMap.on("click", function(res) {
        console.log(res);
        if ("scatter" === res.componentSubType) {
          alert(`点击了 ${res.name} 图标`);
        }
        if ("map" === res.componentSubType) {
          This.cityValue = res.name;
          This.goRule("maodian");
          queryByAreaName(res.name).then((reps) => {
            This.imgList = reps.data;
          });
        }
      });
    },

    // 最新学校列表
    getnewList() {
      videosNewest().then((res) => {
        if (res.status) {
          this.schoolName= res.data[0].schoolName
          this.title= res.data[0].title
          this.newVideo = res.data[0].videoPath;
        } else {
          this.$message("获取最新视频列表失败！");
        }
      });
      schoolsHotsapi().then((res) => {
        if (res.status) {
          this.superurl = res.data;
        } else {
          this.$message("获取热门学校失败！");
        }
      });
    },

    //移动
    move() {
      this.timer = setInterval(this.starmove, 5000);
    },

    //点击按钮左移
    zuohua() {
      if (this.superurl.length * 404 + this.calleft > 808) {
        this.calleft -= 404;
        if (this.calleft < -this.superurl.length * 404) {
          this.calleft = -this.superurl.length * 404;
        }
      }
    },
    //点击按钮右移
    youhua() {
      this.calleft += 404;
      if (this.calleft > 0) {
        this.calleft = 0;
      }
    },
  },
};
</script>
<style lang="less">
.Navigation {
  /*超链接图片*/

  #divAdd {
    /*width: 100%;*/
    /*min-width: 1200px;*/
    width: 980px;
    margin: 0px auto;
  }

  .divAdd-con {
    margin: 0px auto;
    width: 980px;
    overflow: auto;
    padding: 30px 0px;
  }

  .divAdd-con img {
    float: left;
  }

  .divAddleft img {
    float: left;
    margin-bottom: 7px;
  }

  .divAddleft {
    float: left;
    display: inline;
    width: 370px;
  }

  .divAddrt {
    float: right;
    display: inline;
    margin-top: 7px;
  }

  .back_add {
  }

  .divAdd-con img {
    border: none;
  }

  a:focus,
  a:hover {
    color: #23527c;
  }

  .threeImg {
    height: 278px;
    min-width: 980px;
  }

  .threeImg .Containt ul {
    transition: 0.5s linear;
    margin: 0 auto;
    position: absolute;
    left: 0px;
    cursor: pointer;
    height: 100%;
  }

  .threeImg .Containt ul li {
    width: 394px;
    margin-right: 10px;
    float: left;
  }

  .threeImg .Containt ul li img {
    height: 278px;
    width: 394px;
  }
  .threeImg .Containt ul li img:hover {
    opacity: 0.6;
  }

  .Containt {
    position: relative;
    width: 980px;
    height: 280px;
    overflow: hidden;
    margin: 0 auto;
  }

  .iconleft {
    position: absolute;
    width: 20px;
    height: 80px;
    font-weight: bold;
    font-size: 50px;
    top: 65px;
    z-index: 99999;
    padding-top: 48px;
    color: #d14649;
    vertical-align: middle;
  }

  .iconright {
    position: absolute;
    width: 20px;
    height: 80px;
    font-weight: bold;
    font-size: 50px;
    top: 65px;
    right: 25px;
    z-index: 99999;
    padding-top: 48px;
    color: #d14649;
    vertical-align: middle;
  }
  background-color: #0e092b;
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .box {
    .info{
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC, HarmonyOS_Regular, Helvetica Neue, Microsoft YaHei, sans-serif;
      font-weight: 500;
      -webkit-font-smoothing: antialiased;
      color: #18191C;
      color: var(--text1);
      line-height: 24px;
      margin-top: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span{padding-left: 10px;}
    }
    .title {
      text-align: center;
      line-height: 80px;
    }
    .title2 {
      padding-left: 105px;
      text-align: left;
      line-height: 30px;
      font-size: 20px;
    }
    .imgList {
      li {
        width: 33.3%;
        float: left;
        text-align: center;
        margin-bottom: 50px;
        img {
          width: 177px;
          height: 177px;
        }
      }
    }
  }
}
</style>
